//获取签到记录列表
const getSignList = `/gateway/hc-external/nursery/getSignInList`;
//根据日期获取场次信息列表
const getSessionList = `/gateway/hc-external/nursery/getSessionList`;
//签到
const goSignIn = `/gateway/hc-external/nursery/signIn`;
export {
  getSignList,
  getSessionList,
  goSignIn
};
