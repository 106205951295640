<template>
  <div :class="['kindergartensSign']">
    <!-- tab栏 -->
    <div class="tab common">
      <div
        :class="['item', actived == index ? 'actived' : '']"
        v-for="(item, index) in tabList"
        :key="index"
        @click="handelSelectTab(item, index)"
      >
        <span>
          {{ item.label }}
        </span>
        <span v-show="actived == index" class="spot"></span>
      </div>
    </div>
    <div class="course">
      <!-- 日期选择 -->
      <div class="date common">
        <div class="date-left">日期选择</div>
        <div class="date-right" @click="showNurseryDate">
          <span v-if="form.dayData">{{ form.dayData }}</span>
          <span v-else> 请选择日期 </span>
          <img src="./img/arrow.png" alt="" />
        </div>
      </div>
      <div class="date common" v-if="actived == 1">
        <div class="date-left">场次选择</div>
        <div class="date-right" @click="showNurseryTime">
          <span v-if="form.session">{{ form.session }}</span>
          <span v-else> 请选择场次 </span>
          <img src="./img/arrow.png" alt="" />
        </div>
      </div>
      <!-- 表格头部 -->
      <div class="table-header common" v-if="actived == 0">
        <span v-for="(item, index) in tableHeader" :key="index">{{
          item.label
        }}</span>
      </div>
      <div class="table-header common" v-if="actived == 1">
        <span
          class="nursery"
          v-for="(item, index) in kindergartensTableHeader"
          :key="index"
          >{{ item.label }}</span
        >
      </div>
      <!-- 表格数据 -->
      <v-list
        @onLoad="onLoad"
        :finished="finished"
        ref="list"
        :finishedText="finishedText"
      >
        <div class="table-content" v-if="tableList.length > 0">
          <div
            :class="['item', actived == 1 ? 'nursery' : '']"
            v-for="(item, index) in tableList"
            :key="index"
          >
            <div class="txt">{{ item.parentsName }}</div>

            <div class="phone" @click="toAlink(item.parentsPhone)">
              <img src="./img/phone.png" alt="" />
            </div>
            <div class="txt" v-if="actived == 0">
              {{ item.timeDuring == 1 ? "上午" : "下午" }}
            </div>
            <div class="txt">
              {{ item.signInTime ? item.signInTime : "--" }}
            </div>
            <div class="operate">
              <span
                class="operate2"
                @click="goSing(item, 1)"
                v-if="item.signInType == 0"
                >签到</span
              >
              <span
                class="operate3"
                @click="goSing(item, 4)"
                v-else-if="item.signInType == 1"
                >签出</span
              >

              <span class="operate4" v-else-if="item.signInType == 2"
                >缺席</span
              >
              <span class="operate2" v-else-if="item.signInType == 4"
                >已签到</span
              >
            </div>
          </div>
        </div>
      </v-list>
    </div>
    <!-- 托幼签到日期选择器 -->
    <v-dateTimePicker
      type="date"
      :value="form.dayData"
      :minDate="new Date(2023, 0, 1)"
      :valueShow.sync="isShowNurseryDate"
      :isAuto="true"
      @confirmPicker="confirmNurseryDate"
    ></v-dateTimePicker>
    <!-- 选择场次遮罩层 -->
    <v-popup v-model="popupShow" height="80%" :closeable="false">
      <div class="popup_box">
        <div class="popupTopBox">
          <div class="name">请选择场次</div>
        </div>
        <!-- 场次列表 -->
        <div class="bottom_box">
          <div
            :class="['item', { item_bg_change: item.isSelect }]"
            v-for="(item, index) in sessionsList"
            :key="index"
          >
            <div
              style="width: 100%; height: 100%"
              @click.stop="selectSession(item, index)"
            >
              <div class="pointBox">
                <div v-if="item.isSelect" class="active"></div>
                <div v-else class="noClick"></div>
              </div>
              <div class="time">
                {{ item.session }}
              </div>
            </div>
          </div>
          <div class="nodata" v-if="sessionsList.length === 0">暂无场次</div>
        </div>
        <!-- submit按钮 -->
        <div
          class="inbtnBox"
          v-if="sessionsList.length > 0"
          @click.stop="confirmSubmit"
        >
          确定
        </div>
        <div class="emptyBox"></div>
      </div>
    </v-popup>
    <!-- 拨打电话弹窗 -->
    <v-dialog
      confirmButtonColor="#5E5AFF"
      v-model="isDialog_phone"
      @confirm="confirm_phone"
    >
      <p class="dialogTitle">提示</p>
      <p class="dialogText">确认拨打电话：{{ phoneNum }}吗？</p>
    </v-dialog>
    <!-- 签到/签出弹窗 -->
    <div class="signDialog" v-if="isShowSignDialog">
      <div class="dialogMain">
        <div class="item-t">
          <div class="item-l">
            {{ signParmas.signInType == 1 ? "签到提示" : "签出提示" }}
          </div>
          <div class="item-r" @click="isShowSignDialog = false">
            <img src="./img/close.png" alt="" />
          </div>
        </div>
        <div class="item-c">
          <div class="item-pic">
            {{ signParmas.signInType == 1 ? "签到" : "签出" }}照片:
          </div>
          <div class="item-upload">
            <v-upload
              class="oItem-b_upload"
              :imgUrl.sync="signParmas.signInImage"
              :activityPicture.sync="signParmas.signInImage"
              :maxCount="1"
              ref="load"
            ></v-upload>
          </div>
        </div>
        <div class="item-b" @click="confirm">确认</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { getSignList, getSessionList, goSignIn } from "./api";
export default {
  name: "kindergartensSign",
  props: {},

  data() {
    return {
      finished: false,
      finishedText: "没有更多了",
      // tab标签数据
      tabList: [
        {
          value: 1,
          label: "课程签到",
        },
        {
          value: 2,
          label: "时段签到",
        },
      ],
      actived: 0, // 动态类名
      form: {
        dayData: moment(new Date()).format("YYYY-MM-DD"), // 日期
        session: "", // 场次
      },
      isShowNurseryDate: false, // 是否展示托幼签到日期选择器
      tableHeader: [
        {
          value: 0,
          label: "姓名",
        },
        {
          value: 1,
          label: "手机",
        },
        {
          value: 2,
          label: "时间",
        },
        {
          value: 3,
          label: "签到时间",
        },
        {
          value: 4,
          label: "状态",
        },
      ],
      kindergartensTableHeader: [
        {
          value: 0,
          label: "姓名",
        },
        {
          value: 1,
          label: "手机",
        },

        {
          value: 2,
          label: "签到时间",
        },
        {
          value: 3,
          label: "状态",
        },
      ],
      tableList: [],
      phoneNum: "", //电话号码
      isDialog_phone: false, // 是否显示拨打电话弹窗
      popupShow: false, // 场次弹窗
      sessionsList: [], // 场次列表
      type: 1, // 签到形式
      isShowSignDialog: false, //签到/签出弹窗
      signParmas: {
        userId: "",
        id: "",
        signInImage: "", //签到签出图片
        signInType: "", //签到签出状态：传1签到，传4签出
      },
    };
  },
  created() {},
  mounted() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  methods: {
    onLoad() {
      this.getSignList();
    },
    async getSignList(session) {
      let params = {
        type: this.type,
        dayData: this.form.dayData,
        session,
      };
      let res = await this.$axios.get(getSignList, { params });
      if (res.code === 200) {
        if (res.data.pages <= res.data.current) {
          this.finished = true;
        } else {
          this.$refs.list._data.loading = false;
        }
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.finishedText = "";
          this.tableList = [];
          return;
        }
        this.tableList = res.data.records;
      } else {
        this.tableList = [];
        this.finished = true;
      }
    },
    // 选择场次
    selectSession(item, index) {
      this.sessionsList.forEach((ele) => {
        ele.isSelect = false;
      });
      item.isSelect = true;
      this.form.session = item.session;
    },
    //选择tab
    async handelSelectTab(item, index) {
      this.actived = index;
      this.type = item.value;
      this.form.session = "";
      if (this.type == 1) {
        this.onLoad();
      } else {
        this.initData();
        await this.getSessionList();
        if (this.form.session && this.form.dayData) {
          await this.getSignList(this.form.session);
        }
      }
    },
    showNurseryDate() {
      this.isShowNurseryDate = true;
    },
    //初始化数据
    initData() {
      this.tableList = [];
      this.sessionsList = [];
      this.finishedText = "";
    },
    // 点击场次展示
    showNurseryTime() {
      this.popupShow = true;
    },
    confirmNurseryDate(val) {
      this.form.dayData = moment(val).format("YYYY-MM-DD");
      if (this.type == 1) {
        this.getSignList();
      } else {
        this.form.session = "";
        this.initData();
        this.getSessionList();
      }
    },
    // 场次查询
    async getSessionList() {
      let params = {
        dayData: this.form.dayData,
      };
      let res = await this.$axios.get(getSessionList, { params });
      if (res.code === 200) {
        if (res.data.length > 0) {
          this.sessionsList = res.data;
        } else {
          this.form.session = "";
          this.sessionsList = [];
          this.$toast("暂无场次");
        }
      }
    },
    toAlink(item) {
      this.isDialog_phone = true;
      this.phoneNum = item;
    },
    //拨打电话
    confirm_phone() {
      window.location.href = `tel://${this.phoneNum}`;
    },
    // 确认场次选择
    confirmSubmit() {
      if (this.form.session && this.form.dayData) {
        this.getSignList(this.form.session);
      }
      this.popupShow = false;
    },
    // 签到
    goSing(item, signInType) {
      this.signParmas.userId = this.userId;
      this.signParmas.id = item.id;
      this.signParmas.signInType = signInType;
      this.signParmas.signInImage = "";
      this.isShowSignDialog = true;
    },
    // 确认
    confirm() {
      if (!this.signParmas.signInImage) {
        this.$toast(
          this.signParmas.signInType == 1 ? "请选择签到图片" : "请选择签出图片"
        );
        return;
      }
      this.$axios
        .post(`${goSignIn}`, this.$qs.stringify(this.signParmas))
        .then(async (res) => {
          if (res.code === 200) {
            if (this.type == 1) {
              await this.getSignList();
            } else {
              await this.getSignList(this.form.session);
            }
            this.$toast(
              this.signParmas.signInType == 1 ? "签到成功" : "签出成功"
            );
          } else {
            this.$toast(res.msg);
          }
          this.isShowSignDialog = false;
        });
    },
  },
};
</script>

<style scoped lang="less">
.kindergartensSign {
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  box-sizing: border-box;
  background: url("./img/bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 30px;
  .common {
    box-sizing: border-box;
    height: 88px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(236, 240, 237, 0.5);
    border-radius: 8px;
  }
  .tab {
    display: flex;
    align-items: center;

    .item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #71a4ff;
      line-height: 34px;
      .spot {
        width: 10px;
        height: 10px;
        background: #ffb92d;
        border-radius: 50%;
        position: relative;
        top: -15px;
        left: 2px;
      }
    }
    .actived {
      position: relative;
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(54, 123, 244, 0.85);
      line-height: 42px;
    }
  }
  .date {
    margin: 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px 0 34px;
    .date-left {
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 40px;
    }
    .date-right {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 34px;
      span {
        margin-right: 10px;
      }
      img {
        vertical-align: middle;
        width: 16px;
        height: 24px;
      }
    }
  }
  .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      width: 20%;
      text-align: center;
      font-size: 26px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 36px;
    }
    .nursery {
      width: 25%;
    }
  }

  .table-content {
    margin-top: 26px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(236, 240, 237, 0.5);
    border-radius: 8px;
    padding: 38px 0;
    overflow-y: scroll;
    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 36px;
      }

      > div {
        width: 20%;
        text-align: center;
      }
      .txt {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 40px;
        word-wrap: break-word;
      }
      .phone {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 40px;
          height: 40px;
        }
      }
      .operate {
        display: flex;
        align-items: center;
        justify-content: center;
        // 未开始
        .operate1 {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 50px;
          background: #e5e5e5;
          border-radius: 50px;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #aeaeae;
          line-height: 34px;
        }
        // 签到
        .operate2 {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 50px;
          background: #007eff;
          border-radius: 50px;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 34px;
        }
        //签出
        .operate3 {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 50px;
          background: #ffce49;
          border-radius: 50px;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 34px;
        }
        //缺席
        .operate4 {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 50px;
          background: #adadad;
          border-radius: 50px;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 34px;
        }
        // 已签
        .operate5 {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 50px;
          background: #ffffff;
          border: 2px solid #007eff;
          border-radius: 50px;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #007eff;
          line-height: 34px;
        }
      }
    }
    .nursery {
      > div {
        width: 25%;
      }
    }
  }
  .popup_box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    .popupTopBox {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      .name {
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(54, 123, 244, 0.85);
        line-height: 42px;
      }
    }
    .bottom_box {
      width: 100%;
      height: calc(100% - 250px);
      overflow: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      .nodata {
        margin-top: 50px;
        text-align: center;
        font-size: 30px;
        color: #999;
      }

      .item_bg_change {
        background: rgba(0, 0, 0, 0.05);
      }

      .item {
        width: 100%;
        height: 104px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.05);
        padding: 0 32px;
        position: relative;
        box-sizing: border-box;
        .canOrderSum {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 32px;
          font-size: 30px;
          color: rgba(0, 0, 0, 0.85);
        }
        .time {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 88px;
          font-size: 32px;
          color: rgba(0, 0, 0, 0.85);
        }

        .timeCanNot {
          color: rgba(0, 0, 0, 0.25);
        }

        .pointBox {
          position: absolute;
          left: 32px;
          top: 50%;
          transform: translateY(-50%);

          .disable {
            width: 32px;
            height: 32px;
            background: rgba(0, 0, 0, 0.25);
            border-radius: 50%;
          }

          .active {
            box-sizing: border-box;
            width: 32px;
            border-radius: 50%;
            height: 32px;
            background: #ffffff;
            border: 2px solid #615dff;
            background: #5f5bff;
          }

          .noClick {
            box-sizing: border-box;
            width: 32px;
            border-radius: 50%;
            height: 32px;
            background: #ffffff;
            border: 2px solid #615dff;
          }
        }
      }
    }
    .inbtnBox {
      height: 66px;
      width: 550px;
      background: linear-gradient(180deg, #9591ff 0%, #5f5bff 100%);
      border-radius: 10px;
      text-align: center;
      line-height: 66px;
      font-size: 30px;
      color: #fff;
      margin: 0 auto;
      margin-top: 58px;
    }
    .emptyBox {
      height: 82px;
      width: 100%;
    }
  }
}
.isFixed {
  padding-top: 80px;
}
.dialogTitle {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  color: #333333;
  padding: 50px 0 26px 0;
}
.dialogText {
  text-align: center;
  font-size: 30px;
  color: rgba(0, 0, 0, 0.5);
  padding-bottom: 40px;
}

.signDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  .dialogMain {
    background: #ffffff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    white-space: nowrap;
    box-sizing: border-box;
    width: 570px;
    border-radius: 16px;
    overflow: hidden;
    .item-t {
      position: relative;
      padding: 30px 0 20px 0;
      display: flex;
      align-items: center;
      border-bottom: 2px solid #f3f3f3;

      .item-l {
        width: 100%;
        text-align: center;
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 44px;
      }
      .item-r {
        cursor: pointer;
        position: absolute;
        right: 30px;
        top: 30px;
        width: 40px;
        height: 40px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .item-c {
      padding: 34px 0 84px 0;
      border-bottom: 2px solid #f3f3f3;
      .item-pic {
        padding-left: 42px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 40px;
      }
      .item-upload {
        margin-top: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        ::v-deep .van-uploader__upload {
          width: 194px;
          height: 194px;
        }
        ::v-deep .van-uploader__preview {
          border: 0;
          .van-uploader__preview-image {
            width: 194px;
            height: 194px;
          }
          .van-uploader__preview-delete {
            width: 28px;
            height: 28px;
            border-radius: 0 0 0 24px;
            .van-uploader__preview-delete-icon {
              position: absolute;
              top: -7px;
              right: -7px;
              display: block;
            }
          }
        }
      }
    }
    .item-b {
      cursor: pointer;
      padding: 26px 0 28px 0;
      text-align: center;
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #3680ff;
      line-height: 44px;
    }
  }
}
</style>
